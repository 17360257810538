import { FC } from 'react';
import { dataAttributeProps } from '../../../../utils/ComponentUtils';
import { ChevronIcon, ChevronType } from '../ChevronIcon';
import IconProps from '../IconProps';

interface DropDownIconProps extends IconProps {
  open: boolean;
}

const DropDownIcon: FC<DropDownIconProps> = (props) => {
  const { open } = props;

  return <ChevronIcon {...dataAttributeProps(props)} type={open ? ChevronType.UP : ChevronType.DOWN} {...props} />;
};

export default DropDownIcon;
