import { FC, KeyboardEvent, ReactElement, createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FCWithChildren } from '../../../types/FCWithChildren';
import Select, {
  ActionMeta,
  GroupBase,
  OptionsOrGroups,
  SingleValue,
  PropsValue,
  SelectInstance,
  SelectComponentsConfig,
  MultiValue,
} from 'react-select';
import AsyncSelect from 'react-select/async';
import {
  BaseChevron,
  BaseControlRenderer,
  BaseOptionRenderer,
  BasePlaceholderRenderer,
  DefaultClearIndicator,
  DefaultHeadingRenderer,
  DefaultIndicatorSeperator,
  DefaultInput,
  DefaultOptionRenderer,
  DefaultValueContainer,
  Item,
  MenuListRenderer,
  MultiValueRemoveRenderer,
  MultiValueRenderer,
  NoOptionMessage,
  SelectContainerRenderer,
} from './DropdownDefaultComponents';
import { ariaAttributeProps, dataAttributeProps } from '../../../utils/ComponentUtils';
import PlusIcon from '../icon/PlusIcon';

export enum DropdownSize {
  S,
  M,
}

type DropdownProps<TItem extends Item> = {
  options?: OptionsOrGroups<TItem, GroupBase<TItem>>;
  isFetching?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  value?: PropsValue<TItem>;
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  label?: string;
  customSingleValueRenderer?: FC<TItem>;
  customListRenderer?: FC<TItem>;
  dropdownListWrapper?: FCWithChildren;
  customComponents?: Partial<SelectComponentsConfig<TItem, boolean, GroupBase<TItem>>>;
  searchable?: boolean;
  size?: DropdownSize;
  wrapperClassName?: string;
  errorState?: boolean;
  onClear?: () => void;
  menuPosition?: 'left' | 'right';
  customSearchFilter?: (candidate: TItem, search: string) => boolean;
  onTertiaryButtonClick?: () => void;
  tertiaryButtonTitle?: string;
  tertiaryButtonIcon?: ReactElement | null;
  allowCreation?: boolean;
  allowCreationRegex?: RegExp;
  onCreateOption?: (value: string) => void;
  required?: boolean;
  helpText?: string;
  id?: string;
  isAsync?: boolean;
  loadOptions?: (inputValue: string, callback: (options: TItem[]) => void) => void;
  highlightSearchMatch?: boolean;
  closeOnSelect?: boolean;
  forceOpen?: boolean;
  isFixed?: boolean;
} & (
  | {
      isMulti: true;
      onChange: (newValue: TItem[]) => void;
      onRemove: (newValue: TItem) => void;
      autoAddChars?: string[];
    }
  | {
      isMulti?: false | undefined;
      onChange: (newValue: TItem) => void;
    }
);

const DropdownSelect = <TItem extends Item>(props: DropdownProps<TItem>) => {
  const {
    id,
    options,
    isFetching,
    onOpen,
    onClose,
    onChange,
    value,
    className,
    disabled,
    placeholder,
    label,
    customListRenderer,
    dropdownListWrapper,
    size,
    searchable,
    onClear,
    customComponents,
    wrapperClassName = 'w-full',
    errorState,
    menuPosition = 'left',
    customSearchFilter,
    tertiaryButtonTitle,
    tertiaryButtonIcon = <PlusIcon className="h-3 w-3" />,
    onTertiaryButtonClick,
    allowCreation,
    isMulti,
    onCreateOption,
    required,
    helpText,
    isAsync,
    loadOptions,
    allowCreationRegex,
    closeOnSelect = true,
    forceOpen,
    isFixed = true,
  } = props;
  const [open, setOpen] = useState(false);
  const selectRef = useRef<SelectInstance<TItem, boolean>>(null);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (forceOpen) {
      selectRef.current?.openMenu('first');
    }
  }, [forceOpen]);

  const onChangeInternal = useCallback(
    (newValue: SingleValue<TItem> | MultiValue<TItem>, actionMeta: ActionMeta<TItem>) => {
      if (actionMeta.action === 'clear') {
        onClear && onClear();
      } else if (actionMeta.action === 'select-option') {
        onChange(newValue as TItem & TItem[]);
      }
    },
    [onChange, onClear],
  );

  const getOptionLabel = useCallback((item: Item) => {
    return item.text;
  }, []);

  const getOptionValue = useCallback((item: Item) => {
    return `${item.id}`;
  }, []);

  const filterOptions = useCallback(
    (candidate: { label?: string; value: string; data: Item }, search: string) => {
      if (!search || !candidate) {
        return true;
      }

      return customSearchFilter
        ? customSearchFilter(candidate.data as TItem, search)
        : (candidate.label?.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) ?? -1) > -1;
    },
    [customSearchFilter],
  );

  const dataTags = useMemo(
    () => dataAttributeProps(props),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // NO
    ],
  );

  const handleKeyDown = useCallback(
    (evt: KeyboardEvent<HTMLDivElement>) => {
      if (!allowCreation || !inputValue) return;
      const isValid = allowCreationRegex ? allowCreationRegex.test(inputValue) : true;

      if (evt.key === 'Enter' || ('autoAddChars' in props ? props.autoAddChars?.includes(evt.key) : false)) {
        evt.preventDefault();
        if (!isValid) return;
        onCreateOption && onCreateOption(inputValue);
        setInputValue('');
      }
    },
    [allowCreation, allowCreationRegex, inputValue, onCreateOption, props],
  );

  const contextValues = useMemo(
    () => ({
      props,
      ItemRenderer: customListRenderer || DefaultOptionRenderer,
      placeholder,
      hasFocus: !!(selectRef.current && document.activeElement === selectRef.current.inputRef),
      label,
      errorState,
      className,
      size,
      required,
      helpText,
      open,
      isFetching,
      dataTags,
      menuPosition,
      dropdownListWrapper,
      onTertiaryButtonClick,
      tertiaryButtonTitle,
      tertiaryButtonIcon,
      wrapperClassName,
      inputValue,
    }),
    [
      className,
      customListRenderer,
      dataTags,
      dropdownListWrapper,
      errorState,
      helpText,
      isFetching,
      label,
      menuPosition,
      onTertiaryButtonClick,
      open,
      placeholder,
      props,
      required,
      size,
      tertiaryButtonIcon,
      tertiaryButtonTitle,
      wrapperClassName,
      inputValue,
    ],
  );

  const dropdownComponents = useMemo(
    () => ({
      IndicatorSeparator: DefaultIndicatorSeperator,
      Option: BaseOptionRenderer,
      SingleValue: BasePlaceholderRenderer,
      Placeholder: BasePlaceholderRenderer,
      ValueContainer: DefaultValueContainer,
      SelectContainer: SelectContainerRenderer,
      Control: BaseControlRenderer,
      DropdownIndicator: BaseChevron,
      ClearIndicator: DefaultClearIndicator,
      MenuList: MenuListRenderer,
      Input: DefaultInput,
      GroupHeading: DefaultHeadingRenderer,
      NoOptionsMessage: NoOptionMessage,
      MultiValueRemove: MultiValueRemoveRenderer,
      MultiValue: MultiValueRenderer,
      ...(customComponents || {}),
    }),
    [customComponents],
  );

  const SelectComponent = isAsync ? AsyncSelect : Select;

  return (
    <DropdownSelectProvider value={contextValues}>
      <SelectComponent
        id={id}
        options={options}
        onChange={onChangeInternal}
        value={value}
        className={className}
        isDisabled={disabled}
        placeholder={placeholder}
        components={dropdownComponents}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        isSearchable={!!searchable || !!customSearchFilter || !!allowCreation}
        filterOption={filterOptions}
        isClearable={!!onClear}
        onMenuClose={() => {
          setOpen(false);
          onClose && onClose();
        }}
        onMenuOpen={() => {
          setOpen(true);
          onOpen && onOpen();
        }}
        ref={selectRef}
        menuShouldScrollIntoView={false}
        minMenuHeight={150}
        menuPlacement="auto"
        isMulti={isMulti}
        inputValue={inputValue}
        onInputChange={setInputValue}
        onKeyDown={handleKeyDown}
        {...ariaAttributeProps(props)}
        tabIndex={0}
        menuPosition={isFixed ? 'fixed' : 'absolute'}
        loadOptions={isAsync ? loadOptions : undefined}
        defaultOptions={isAsync}
        cacheOptions={isAsync}
        closeMenuOnSelect={closeOnSelect}
        menuIsOpen={forceOpen ? true : undefined}
      />
    </DropdownSelectProvider>
  );
};

export default DropdownSelect;

type Context<TItem extends Item> = {
  ItemRenderer: FC<TItem>;
  isFetching?: boolean;
  hasFocus: boolean;
  open: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataTags: any;
  inputValue: string;

  props: DropdownProps<TItem>;
  placeholder: DropdownProps<TItem>['placeholder'];
  errorState: DropdownProps<TItem>['errorState'];
  className: DropdownProps<TItem>['className'];
  size: DropdownProps<TItem>['size'];
  required: DropdownProps<TItem>['required'];
  helpText: DropdownProps<TItem>['helpText'];
  label: DropdownProps<TItem>['label'];
  menuPosition: DropdownProps<TItem>['menuPosition'];
  dropdownListWrapper: DropdownProps<TItem>['dropdownListWrapper'];
  onTertiaryButtonClick: DropdownProps<TItem>['onTertiaryButtonClick'];
  tertiaryButtonTitle: DropdownProps<TItem>['tertiaryButtonTitle'];
  tertiaryButtonIcon: DropdownProps<TItem>['tertiaryButtonIcon'];
  wrapperClassName: DropdownProps<TItem>['wrapperClassName'];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DropdownSelectContext = createContext<Context<any>>(null!);
const DropdownSelectProvider = DropdownSelectContext.Provider;
export const useDropdownSelect = () => useContext(DropdownSelectContext);
